
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';

import BuyBoxFilter from '@/components/buybox/BuyBoxFilter.vue';
import BuyBoxRow from '@/components/buybox/BuyBoxRow.vue';
import {
	GetTitleOffersDocument,
	GetTitleOffersQuery,
	GetTitleOffersQueryVariables,
} from '@/components/buybox/graphql/queries/GetTitleOffers.query';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';

const OfferReporter = () => import('@/components/buybox/OfferReporter.vue');
const BuyBoxFastRow = () => import('@/components/buybox/BuyBoxFastRow.vue');
const BuyBoxNoOfferRow = () => import('@/components/buybox/BuyBoxNoOfferRow.vue');
const PromotionRow = () => import('@/components/buybox/PromotionRow.vue');
// APPLE_QUALTIY_TV_EXP
const QualityTvRow = () => import('@/components/buybox/QualityTvRow/QualityTvRow.vue');
// APPLE_QUALTIY_TV_EXP
import { ImpressionTrackingEvents } from '@/enums/events';

import { NarrativeObjectType, OfferLocation } from '@/helpers/offer-helper';
import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { timestamp } from '@/helpers/date-helper';

import type { TitleOffers } from '@/interfaces/buybox';
import { OfferPresentationType } from '@/interfaces/titles';

import { ObjectType, Offer as OfferGraphql, Scoring } from '@/@types/graphql-types';
import { MonetizationType, PresentationType, SraEventAction } from '@/@types/graphql-types';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { useApolloData } from '@/helpers/composables/useApolloData';
import { type UsePromotion, allowedPromotionLanguages, usePromotion } from '@/helpers/composables/usePromotion';

import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import { useArbitrage } from '@/helpers/composables/useArbitrage';
import { NewBuyBoxView } from '@/stores/user.store';
import { excludePackages } from '@/constants/buybox-excluded-packages.constant';

import { appleQualityTvCountries } from './QualityTvRow/data';
import { CountriesToBeSorted, sortOffersByMonetizationPotential } from './utils/buy-box-helpers';
import BuyBoxCountrySwitcher from '@/components/buybox-new/BuyBoxCountrySwitcher.vue';

// AMAZON_OS_FREE_TRIAL_EXP_2
import {
	AmazonOSFreeTrialExp2Name,
	AmazonOSFreeTrialExp2Variants,
} from '@/components/experiments/AmazonOSFreeTrialExp2';
import AmazonOSFreeTrialExpPromotionRow from '@/components/experiments/AmazonOSFreeTrialExp2/AmazonOSFreeTrialExpPromotionRow.vue';
// AMAZON_OS_FREE_TRIAL_EXP_2
import Provider from '@/interfaces/provider';
// AQTV_TITLEDETAIL_PLACEMENTS
import { computed, inject, provide, Ref, ref } from 'vue';
import { QtvCtaExpVariants } from '../experiments/QtvCtaExp';
import { trackQtvExpCta } from '../experiments/QtvCtaExp/tracking';

import { useProvideClickoutPlacement } from '@/helpers/composables/useProvideClickoutPlacement';

// ATV_PROMO_EXP
import {
	AppleBuyboxPromotionExpName,
	AppleBuyboxPromotionExpVariants,
} from '@/components/experiments/AppleBuyboxPromotionExp';
import { trackAppleBuyboxPromotionExp } from '@/components/experiments/AppleBuyboxPromotionExp/tracking';

// ATV_PROMO_EXP

const Constant = namespace('constant');
const Language = namespace('language');
const Routing = namespace('routing');
const User = namespace('user');
const Experiment = namespace('experiment');

@Component({
	name: 'BuyBox',
	components: {
		OfferReporter,
		BuyBoxFilter,
		ProviderIcon,
		BuyBoxRow,
		BuyBoxFastRow,
		BuyBoxNoOfferRow,
		PromotionRow,
		QualityTvRow,
		BuyBoxCountrySwitcher,
		// AMAZON_OS_FREE_TRIAL_EXP_2
		AmazonOSFreeTrialExpPromotionRow,
		// AMAZON_OS_FREE_TRIAL_EXP_2
	},
	apollo: {
		offers: {
			query: GetTitleOffersDocument,
			variables(): GetTitleOffersQueryVariables {
				return this.queryVariables;
			},
			update(data: GetTitleOffersQuery) {
				if (data.node == null) return;

				if (
					data.node.__typename === 'Episode' ||
					data.node.__typename === 'Movie' ||
					data.node.__typename === 'Season' ||
					data.node.__typename === 'Show'
				) {
					const { buy, rent, fast, free, flatrate, offerCount, maxOfferUpdatedAt, bundles } =
						data.node as GetTitleOffersQuery['node'] & { __typename: 'Movie' };
					const result: TitleOffers = {
						stream: [],
						rent: rent as TitleOffers['rent'],
						buy: buy as TitleOffers['buy'],
						free: free as TitleOffers['free'],
						fast: fast as TitleOffers['fast'],
						cinema: [],
						bundles: bundles as TitleOffers['bundles'],
						offerCount,
						maxOfferUpdatedAt,
					};
					(flatrate as TitleOffers['cinema']).forEach(offer =>
						offer.monetizationType === MonetizationType.Cinema
							? result.cinema.push(offer)
							: result.stream.push(offer)
					);
					return result;
				}
			},
			watchLoading(isLoading) {
				if (!process.server) {
					!this.buyboxLoaded && this.onBuyboxLoadingChange(isLoading);
				}
			},
		},
	},
	setup({
		title,
		isBannerBuyBox,
		showDetails,
		titleObjectId,
		titleObjectType,
		seasonNumber,
		episodeNumber,
		titleId,
		placement,
	}) {
		const { inPromotionCatalogue, promotionOffer, catalogueItem } = usePromotion({
			title,
			offers: () => {
				const { free = [], stream } = useApolloData<TitleOffers>('offers')();
				return [...free, ...stream];
			},
		});

		const titleContext = computed(() => {
			const _titleObjectId = showDetails ? showDetails.objectId : titleObjectId;
			const _titleObjectType = showDetails ? showDetails.objectType : titleObjectType;
			return new SnowplowTitleContextGraphql(_titleObjectId, _titleObjectType, seasonNumber, episodeNumber);
		});

		provide('isBannerBuyBox', () => isBannerBuyBox);
		provide('titleContext', titleContext);
		provide('titleSourceId', titleId);

		// QTV_CTA_EXP
		const qtvCtaExpVariant = inject<Ref<QtvCtaExpVariants | null>>('qtvCtaExpVariant', ref(null));
		// QTV_CTA_EXP
		const { isArbitrating } = useArbitrage();

		useProvideClickoutPlacement(placement);

		return { inPromotionCatalogue, promotionOffer, catalogueItem, isArbitrating, qtvCtaExpVariant };
	},
})
export default class BuyBox extends Mixins(BackendTrackingMixin) {
	buyboxLoaded = false;
	offers: TitleOffers = {
		stream: [],
		rent: [],
		buy: [],
		free: [],
		fast: [],
		cinema: [],
		bundles: [],
		offerCount: 0,
		maxOfferUpdatedAt: '',
	};

	// usePromotion composable exports
	declare inPromotionCatalogue: UsePromotion['inPromotionCatalogue'];
	declare promotionOffer: UsePromotion['promotionOffer'];
	declare catalogueItem: UsePromotion['catalogueItem'];
	declare isArbitrating: boolean;
	declare qtvCtaExpVariant: QtvCtaExpVariants | null;

	// TODO: remove all title* props across <BuyBox> usages
	@Prop({ required: true }) titleId: string;
	@Prop({ required: true }) titleObjectType: NarrativeObjectType; // base title objectType
	@Prop({ required: true }) titleObjectId: number; // base title id
	@Prop({ default: '' }) titleName: string;
	@Prop({ required: false }) title: TitleDetail;
	// You need to pass this prop when title is Season/Episode, because BuyBox component has functionality to set
	// title into Watchlist, but this mutation available only for Movie/Show. So basically when you set Season/Episode
	// to Watchlist we set whole Show to watchlist.
	@Prop({ default: null }) showDetails: {
		id: string;
		objectId: number;
		objectType: ObjectType;
		title: string;
	};
	@Prop({ default: false }) isTitleInWatchlist: boolean;
	@Prop({ default: () => ({}) }) titleScoring: Scoring;
	@Prop({ default: () => null }) seasonNumber: number | null;
	@Prop({ default: () => null }) episodeNumber: number | null;
	@Prop({ default: undefined }) originalReleaseYear: number;
	@Prop({ default: undefined }) sponsoredAd: SponsoredAdFragment | undefined;
	@Prop({ default: false }) isBannerBuyBox: boolean;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Prop() buyBoxSwitchType: NewBuyBoxView;

	// Show filter bar
	@Prop({ default: false, type: Boolean }) showFilter!: boolean;

	// display as inline
	@Prop(Boolean) inline: boolean;

	// no header 'WATCH NOW'
	@Prop({ default: false }) noHeader: boolean;

	@Prop() defaultPresentationType: OfferPresentationType;
	@Prop({ default: false }) isInModal: boolean;

	// START AVOD
	@Prop({ default: false }) isAVODActive: boolean;
	// END AVOD

	@Prop({ default: false }) isEpisodeBuybox: boolean;

	@Prop({ default: 'regular_buybox' }) placement: string;

	@Constant.Getter titleProviderCount: number;
	@Constant.Getter allProvidersById: Record<number, Provider>;

	@Language.Getter declare language: string;
	@Language.Getter declare country: string;

	@Routing.State activeRoute: Route;

	@User.Getter buyboxPresentationType: OfferPresentationType | null;
	@User.State isPremium: boolean;
	@User.State jwId: string;
	@User.Getter isLoggedIn: boolean;
	@User.Action setNewBuyboxView: (view: NewBuyBoxView) => void;
	@User.Getter newBuyboxView: NewBuyBoxView;

	@Experiment.Getter activeVariantsWithControlGroup: Record<string, string>;

	@User.Action setBuyboxPresentationType: (payload: { presentationType: OfferPresentationType | null }) => void;

	@Emit('onWatchlistToggle')
	onWatchlistToggle(createdAt: string | null) {
		return createdAt;
	}

	@Emit('buyboxLoading')
	onBuyboxLoadingChange(isLoading: boolean) {
		if (!isLoading) {
			this.buyboxLoaded = true;
		}

		return isLoading;
	}

	isMounted = false;
	mounted() {
		this.isMounted = true;
		// to update presentationType on Search page
		if (this.$route.query.monetization_types === 'free') {
			this.setBuyboxPresentationType({ presentationType: OfferPresentationType.FREE });
		}
	}

	get queryVariables(): GetTitleOffersQueryVariables {
		const presentationTypes = this.presentationTypeGraphql ? [this.presentationTypeGraphql] : undefined;

		return {
			nodeId: this.titleId,
			country: this.country,
			language: this.language,
			filterBuy: {
				monetizationTypes: [MonetizationType.Buy],
				bestOnly: true,
				preAffiliate: true,
				presentationTypes,
			},

			filterFlatrate: {
				monetizationTypes: [
					MonetizationType.Flatrate,
					MonetizationType.FlatrateAndBuy,
					MonetizationType.Ads,
					MonetizationType.Free,
					MonetizationType.Cinema,
				],
				presentationTypes,
				bestOnly: true,
				preAffiliate: true,
			},
			filterRent: {
				monetizationTypes: [MonetizationType.Rent],
				presentationTypes,
				bestOnly: true,
				preAffiliate: true,
			},
			filterFree: {
				monetizationTypes: [MonetizationType.Ads, MonetizationType.Free],
				presentationTypes,
				bestOnly: true,
				preAffiliate: true,
			},
		};
	}

	episodeBuyBoxPresentationType: OfferPresentationType | null = null;

	get buyBoxActiveType() {
		return this.isEpisodeBuybox ? this.episodeBuyBoxPresentationType : this.buyboxPresentationType;
	}

	get presentationTypeGraphql() {
		// @ts-ignore
		const presentationTypeMap: Record<OfferPresentationType, PresentationType> = {
			[OfferPresentationType._4K]: PresentationType['4K'],
			[OfferPresentationType.HD]: PresentationType.Hd,
			[OfferPresentationType.SD]: PresentationType.Sd,
			[OfferPresentationType.DVD]: PresentationType.Dvd,
			[OfferPresentationType.BLURAY]: PresentationType.Bluray,
			[OfferPresentationType.CANVAS]: PresentationType.Canvas,
		};

		if (this.buyBoxActiveType == null) return null;
		return presentationTypeMap[this.buyBoxActiveType];
	}

	get presentationTypes() {
		return {
			[PresentationType.Hd]: {
				text: 'HD',
				name: 'hd',
			},
			[PresentationType['4K']]: {
				text: '4K',
				name: 'hd',
			},
			[PresentationType.Dvd]: {
				text: 'DVD',
				name: 'dvd',
			},
			[PresentationType.Bluray]: {
				text: 'Blu-Ray',
				name: 'bluray',
			},
		};
	}

	get isFreeBuyBox() {
		return this.buyBoxActiveType === OfferPresentationType.FREE;
	}

	/** Excludes Free. The title does not have stream, rent, buy, or any other offers. */
	get hasNoNonFreeOffers() {
		const offerTypes = ['stream', 'rent', 'buy', 'fast', 'cinema'] as const;
		return offerTypes.reduce((acc, curr) => (acc += (this.filteredOffers?.[curr] ?? []).length), 0) === 0;
	}

	get hasOnlyCinemaOffers() {
		const cinemaOfferCount = this.filteredOffers.cinema.length;
		return cinemaOfferCount > 0 && cinemaOfferCount === this.filteredOffers.offerCount;
	}

	get showPromotionRow() {
		const rawLanguage = this.language.split('-')[0];
		return this.title && !this.isPremium && this.isFreeBuyBox && allowedPromotionLanguages.includes(rawLanguage);
	}

	/** Depending on filters "no offers" can mean different things. */
	get hasNoOffersToShow() {
		// this buybox filter is about Monetization, so here specifically we ask
		// if there are no "FREE" offers only
		if (this.isFreeBuyBox) {
			const hasNoFreeOffers = this.filteredOffers.free.length === 0;

			// if there is a promotion from a provider that does have an offer,
			// then techincally the promotion counts as an "offer".
			if (this.showPromotionRow) {
				return !this.inPromotionCatalogue && hasNoFreeOffers;
			}

			return hasNoFreeOffers;
		}

		return this.hasNoNonFreeOffers || this.hasOnlyCinemaOffers;
	}

	get lastUpdated() {
		return this.filteredOffers.maxOfferUpdatedAt;
	}

	get isTitleDetailPage() {
		return this.activeRoute.name?.includes('app.detail');
	}

	get timestampText() {
		const updated = timestamp(
			this.lastUpdated,
			{ year: 'numeric', month: 'long', day: 'numeric' },
			this.country,
			this.language
		);
		return this.$t('WEBAPP_BUYBOX_UPDATED_TIMESTAMP', {
			services_total: this.titleProviderCount,
			date: updated.date,
			time: updated.time,
		});
	}

	get sponsoredRecommendationPackageId() {
		return this.sponsoredAd?.campaign?.watchNowOffer.package.packageId;
	}

	get sponsoredRecommendationCohort() {
		return this.sponsoredAd?.holdoutGroup ? 0 : 1;
	}

	get excludedPackages() {
		const titleObjectId = this.title.__typename === 'Season' ? this.title?.show.id : this.title.id;
		return excludePackages(this.isLoggedIn, titleObjectId, this.country);
	}

	get filteredOffers() {
		// Start with the full list of offers
		let _offers = this.offers;

		// Filtering out TVOD providers we don't have a partnership with
		const { offerCount, maxOfferUpdatedAt, bundles, ...allOffers } = _offers;

		const filteredOffers = Object.fromEntries(
			Object.entries(allOffers).map(([key, value]) => [
				key,
				value.filter(offer => !this.excludedPackages.includes(offer.package.packageId)),
			])
		);

		_offers = {
			...filteredOffers,
			bundles,
			offerCount,
			maxOfferUpdatedAt,
		} as TitleOffers;

		if (!this.isBannerBuyBox) return _offers;

		// Return filtered offers
		return sortOffersByMonetizationPotential(_offers, this.country as CountriesToBeSorted);
	}

	get offerReporterTitleOffers() {
		return [
			...this.filteredOffers.free,
			...this.filteredOffers.stream,
			...this.filteredOffers.rent,
			...this.filteredOffers.buy,
			...this.filteredOffers.cinema,
		];
	}

	get fastOffers() {
		// Exclude Offers that are no longer active.
		return this.filteredOffers.fast.filter(el => new Date(el.availableToTime) > new Date());
	}

	get showFastRow() {
		return this.isFreeBuyBox || this.buyBoxActiveType === null;
	}

	get trackingContexts() {
		const titleObjectId = this.showDetails ? this.showDetails.objectId : this.titleObjectId;
		const titleObjectType = this.showDetails ? this.showDetails.objectType : this.titleObjectType;
		const titleContext = new SnowplowTitleContextGraphql(
			titleObjectId,
			titleObjectType,
			this.seasonNumber,
			this.episodeNumber
		);

		return (this.additionalContexts || []).concat([titleContext]);
	}

	handleEpisodeFilterChange(presentation: OfferPresentationType) {
		this.setEpisodeBuyboxPresentationType(presentation);
	}

	setEpisodeBuyboxPresentationType(presentationType: OfferPresentationType | null) {
		this.episodeBuyBoxPresentationType = presentationType;
	}

	clickOut(payload: { buyboxOffer: OfferGraphql; isLeavingIconShown: boolean; offerLocation?: OfferLocation }) {
		const clickoutContext = SnowplowClickoutContextGraphql.fromProviderOffer(payload.buyboxOffer);

		if (
			!!this.sponsoredRecommendationPackageId &&
			payload.buyboxOffer.package.packageId === this.sponsoredRecommendationPackageId
		) {
			let contexts: SnowplowContext[] = [];
			if (payload.buyboxOffer) {
				contexts = this.trackingContexts;
				contexts.push(clickoutContext);
			}

			TrackingHelper.trackEvent(
				ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
				{
					action: 'buybox_clickout',
					label: `${this.titleId}_${this.sponsoredRecommendationPackageId}`,
					property: 'buybox',
					value: this.sponsoredRecommendationCohort,
				},
				contexts
			);

			this.$backendTracking.trackSraEvent(SraEventAction.BuyboxClickout, this.sponsoredAd, this.titleId);
		}

		// SEASON_PAGE_BUYBOX_EXP
		const isFreeTrial = payload.offerLocation === 4 ? 'free_trial_' : '';
		// SEASON_PAGE_BUYBOX_EXP

		if (this.isBannerBuyBox) {
			trackAppleBuyboxPromotionExp({
				action: 'click',
				label: `${isFreeTrial}clickout_banner_buybox`,
				contexts: [clickoutContext, ...this.additionalContexts],
				value: OfferLocation.BANNER_BUYBOX,
			});
			// ATV_PROMO_EXP

			if (this.qtvCtaExpVariant) {
				trackQtvExpCta({
					action: 'click',
					label: 'clickout_banner_buybox',
					value: OfferLocation.BANNER_BUYBOX,
				});
			}
		} else {
			trackAppleBuyboxPromotionExp({
				action: 'click',
				label: this.isEpisodeBuybox ? `${isFreeTrial}clickout_episode` : `${isFreeTrial}clickout_buybox`,
				contexts: [clickoutContext, ...this.additionalContexts],
				value: payload.offerLocation,
			});
			// ATV_PROMO_EXP
		}
	}

	// AMAZON_OS_FREE_TRIAL_EXP_2
	get isAmazonOSFreeTrialExp() {
		return !!this.isMounted && this.amazonOSFreeTrialExpVariant;
	}

	get amazonOSFreeTrialExpVariant() {
		return this.activeVariantsWithControlGroup[AmazonOSFreeTrialExp2Name] as AmazonOSFreeTrialExp2Variants;
	}

	get hasAmazonOffer() {
		const offers = [
			...this.filteredOffers.free,
			...this.filteredOffers.stream,
			...this.filteredOffers.rent,
			...this.filteredOffers.buy,
		];
		return offers.some(offer => offer.package.packageId === 9);
	}

	get displayAmazonOSFreeTrialExpPromotionRow() {
		return (
			this.showPromotionRow &&
			!this.qualityTvActive &&
			this.isMounted &&
			this.isAmazonOSFreeTrialExp &&
			this.hasAmazonOffer &&
			this.country === 'US'
		);
	}
	// AMAZON_OS_FREE_TRIAL_EXP_2

	get showPromotionRowWhenArbitrating() {
		return this.isArbitrating && 350 === this.promotionOffer?.package?.packageId;
	}

	get isQualityTvCountry() {
		if (!this.promotionOffer) return false;

		if (this.promotionOffer.package.packageId === 350 && appleQualityTvCountries.includes(this.country))
			return true;

		return this.promotionOffer.package.packageId === 2;

		// return (
		// 	[9, 119].includes(this.promotionOffer.package.packageId) &&
		// 	primeQualityTvCountries.includes(this.country) &&
		// 	this.title.__typename === 'Show'
		// );
	}

	// ATV_PROMO_EXP
	get hideApplePromoExpFreeTrial() {
		return (
			this.applePromoExpVariant &&
			['variant_1', 'variant_2', 'variant_3'].includes(this.applePromoExpVariant) &&
			![...this.filteredOffers.free, ...this.filteredOffers.stream].some(
				offer => offer.package.packageId === 350
			) &&
			!this.inline
		);
	}

	// ATV_PROMO_EXP
	get freeTrialActive() {
		// ATV_PROMO_EXP
		if (this.hideApplePromoExpFreeTrial) return false;
		// ATV_PROMO_EXP
		if (!!this.catalogueItem?.placement) {
			// additional exception: banner, search & guide page buyboxes always render FT
			return !!this.inline || this.catalogueItem?.placement === 'ft';
		}
		return !this.catalogueItem?.placement && !this.qualityTvActive;
	}

	// AMAZON_OS_FREE_TRIAL_EXP_2
	get isApplePromoExpActive() {
		return !!this.isMounted && !!this.amazonOSFreeTrialExpVariant;
	}

	// ATV_PROMO_EXP
	get applePromoExpVariant() {
		return null;
		// if (!this.isMounted || this.country !== 'US' || this.isLoggedIn || this.isArbitrating) return null;
		// return this.activeVariantsWithControlGroup[AppleBuyboxPromotionExpName] as AppleBuyboxPromotionExpVariants;
	}

	get isApplePromoExpQtvVariant() {
		return (
			(this.applePromoExpVariant === 'variant_1' || this.applePromoExpVariant === 'variant_2') &&
			![...this.filteredOffers.free, ...this.filteredOffers.stream].some(offer => offer.package.packageId === 350)
		);
	}

	// ATV_PROMO_EXP

	get qualityTvActive() {
		return (
			!this.isLoggedIn &&
			(!this.isMounted || (this.isMounted && !this.showPromotionRowWhenArbitrating)) &&
			!this.inline &&
			!this.isBannerBuyBox &&
			// either placement is overwritten by yo, or the programmatic decision checks
			// APPLE_PROMO_EXP
			((this.isApplePromoExpQtvVariant && !this.isFreeBuyBox) ||
				// APPLE_PROMO_EXP
				this.catalogueItem?.placement === 'qtv' ||
				(!this.catalogueItem?.placement && this.isQualityTvCountry))
		);
	}

	get amazonPrimeProviderId() {
		if (!!this.allProvidersById[9]) return 9;
		return !!this.allProvidersById[119] ? 119 : null;
	}

	// decides on what provider id will be shown in the quality tv component, if it's being shown at all.
	get qualityTvProviderId() {
		// ATV_PROMO_EXP
		if (this.isApplePromoExpQtvVariant) {
			return 350;
		}
		// ATV_PROMO_EXP

		// check if we don't have an overwrite from yield optimisation
		if (!!this.catalogueItem?.placement) {
			return this.catalogueItem.packageId;
		}

		return this.promotionOffer?.package?.packageId || 350;
	}
}
