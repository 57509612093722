import { defineComponent } from 'vue';

import type {
	VisibilityChangedPayload,
	BatchImpressionTrackingOptions,
} from '@/helpers/composables/useBatchImpressionTracking';
import { useBatchImpressionTracking } from '@/helpers/composables/useBatchImpressionTracking';

import { ClickoutUrl } from '../clickout-helper';

export type OfferVisibilityChangedPayload = VisibilityChangedPayload<ClickoutUrl>;

export const observableOptions = {
	once: false,
	throttle: 300,
	intersection: {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.5, // 50% visibility of each element
	},
} as const;

export function useOfferImpressionTracking(options: BatchImpressionTrackingOptions = {}) {
	// let's derive a group id from title contexts if they're present.
	// this way, we can be sure that we send the title context with all offer impressions for that title.
	// it's problematic otherwise, if we're on the search page with different titles.

	// const entityId = (toValue(options.additionalContexts) || [])
	// 	.filter(context => context.__name === 'TitleContextGraphql')
	// 	.map(context => (context as SnowplowTitleContextGraphql).jwEntityId)
	// 	.find(Boolean);

	return useBatchImpressionTracking<ClickoutUrl>({
		category: 'offer',
		toContext: clickoutUrl => clickoutUrl.contexts.ClickoutContextGraphql,
		observableOptions: options.observableOptions ?? observableOptions,
		additionalContexts: options.additionalContexts,
		additionalProperties: options.additionalProperties,
		// groupId is needed in case we want to group batch impressions specifically
		// temporarily removed, as it was always returning undefined before - need to check with BI if we prefer grouping events with different entityIds with index, or sending separate events
		// groupId: entityId,
	});
}

/** Renderless version */
export default defineComponent({
	name: 'UseOfferImpressionTracking',
	props: ['observableOptions', 'additionalContexts'],
	setup(props, { slots }) {
		return () => slots.default?.(useOfferImpressionTracking(props)) ?? slots[0];
	},
});
